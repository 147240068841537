import React from 'react';
import { useRouter } from 'next/router';
import { SxProps } from '@mui/system';

// components
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';

// contexts
import { usePageLocale } from 'src/contexts';

// helpers
import { getStaticData } from 'src/lib/utils';

interface MainNavProps {
  skipLinkAnchor?: string;
  getStartedTrackingId?: string;
  navbarStyles?: {
    sx: SxProps;
  };
}

const MainNav: React.FC<MainNavProps> = ({
  skipLinkAnchor = '#main-content',
  getStartedTrackingId = '',
  navbarStyles = {},
}) => {
  const { asPath } = useRouter();
  const onBlogPage = asPath.includes('blog');
  const locale = usePageLocale();
  const { mainNavData } = getStaticData({
    locale,
    filePath: 'navigation/main-nav',
  });
  return (
    <>
      <DesktopNavbar
        skipLinkAnchor={skipLinkAnchor}
        onBlogPage={onBlogPage}
        getStartedTrackingId={getStartedTrackingId}
        locale={locale}
        {...navbarStyles}
        {...mainNavData}
      />
      <MobileNavbar
        getStartedTrackingId={getStartedTrackingId}
        locale={locale}
        {...mainNavData}
        {...navbarStyles}
      />
    </>
  );
};

export default MainNav;
